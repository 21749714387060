<template>
  <div>
    <div class="pa-0 px-1 pb-3 w-full d-flex align-center justify-end">
      <v-dialog
        v-model="isFormOpen"
        persistent
        width="500"
      >
        <template #activator="{ on, attrs }">
          <v-btn
            fab
            small
            color="primary"
            :ripple="false"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>
              {{ icons.mdiPlus }}
            </v-icon>
          </v-btn>
        </template>

        <v-card>
          <v-card-title>
            {{ currentForm.id ? '条件 編集' : '新規 条件' }}
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col
                md="6"
                cols="12"
              >
                <v-text-field
                  v-model.number="currentForm.threshold"
                  type="number"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  :prefix="thresholdPrefix"
                  :suffix="thresholdSuffix"
                  label="閾値"
                  :error="!isValid.threshold"
                />
              </v-col>

              <v-col
                md="6"
                cols="12"
              >
                <v-select
                  v-model="currentForm.adjective"
                  :items="adjectives"
                  item-value="value"
                  item-text="text"
                  label="形容詞"
                  hide-details
                  :error="!isValid.adjective"
                  :menu-props="{ auto: true, offsetY: true }"
                />
              </v-col>
            </v-row>

            <slot name="input" />
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              :ripple="false"
              :disabled="!isValidToSubmit"
              :loading="isSubmitting"
              @click="$emit('submit'); isFormOpen = false"
            >
              {{ currentForm.id ? '更新' : '作成' }}
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :ripple="false"
              @click="isFormOpen = false; $emit('cancel')"
            >
              キャンセル
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <v-data-table
      :headers="headers"
      :items="items"
      :header-props="{ sortByText: 'ソート' }"
      hide-default-footer
      disable-pagination
      show-group-by
      no-data-text="データがありません"
    >
      <template #[`group.header`]="{ groupBy, group, remove }">
        <td
          :colspan="headers.length"
          @click="remove"
        >
          <span class="mr-4">{{ headers.find(obj => obj.value === groupBy[0]).text }}</span>

          <strong v-if="groupBy[0] === 'attributes.threshold'">
            {{ thresholdPrefix }}{{
              displayFunctions['attributes.threshold']
                ? displayFunctions['attributes.threshold'](group)
                : group
            }}{{ thresholdSuffix }}
          </strong>

          <strong v-if="groupBy[0] === 'attributes.adjective'">
            {{ adjectiveEnum[group] }}
          </strong>

          <slot
            name="group-header"
            :group-by="groupBy"
            :group="group"
          />
        </td>
      </template>

      <template #[`item.attributes.threshold`]="{item}">
        {{ thresholdPrefix }}{{
          displayFunctions['attributes.threshold']
            ? displayFunctions['attributes.threshold'](item.attributes.threshold)
            : item.attributes.threshold
        }}{{ thresholdSuffix }}
      </template>

      <template #[`item.attributes.adjective`]="{item}">
        {{ adjectiveEnum[item.attributes.adjective] }}
      </template>

      <template
        v-for="headerObj in headersExt"
        #[`item.${headerObj.value}`]="{item}"
      >
        {{
          displayFunctions[headerObj.value]
            ? displayFunctions[headerObj.value](getValue(item, headerObj.value))
            : getValue(item, headerObj.value)
        }}
      </template>

      <template #[`item.actions`]="{item}">
        <span class="d-flex align-center justify-end">
          <v-btn
            icon
            :ripple="false"
            @click="$emit('edit', item); isFormOpen = true"
          >
            <v-icon small>
              {{ icons.mdiPencilOutline }}
            </v-icon>
          </v-btn>

          <yona-edit-dialog
            title="削除の確認"
            btn-color="error"
            save-text="同意の上で削除"
            @save="$emit('destroy', item.id)"
          >
            <template #append-outer-display-name>
              <v-btn
                icon
                :ripple="false"
              >
                <v-icon small>
                  {{ icons.mdiTrashCanOutline }}
                </v-icon>
              </v-btn>
            </template>

            <template #input>
              <span>
                削除を実行してもよろしいですか
              </span>
            </template>
          </yona-edit-dialog>
        </span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { useVModel } from '@vueuse/core'
import {
  ref,
  computed,
  watch,
} from '@vue/composition-api'
import {
  mdiPlus,
  mdiTrashCanOutline,
  mdiPencilOutline,
} from '@mdi/js'
import {
  map,
  every,
  values,
  get,
} from 'lodash'
import YonaEditDialog from '@/views/components/util/YonaEditDialog.vue'

export default {
  components: {
    YonaEditDialog,
  },
  props: {
    value: {
      type: Object,
      required: true,
      default: () => {},
    },
    headersExt: {
      type: Array,
      default: () => [],
    },
    validator: {
      type: Function,
      default: value => value,
    },
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    isSubmitting: {
      type: Boolean,
      default: false,
    },
    thresholdPrefix: {
      type: String,
      default: '',
    },
    thresholdSuffix: {
      type: String,
      default: '',
    },
    displayFunctions: {
      type: Object,
      default: () => ({
        'attributes.threshold': threshold => threshold.toLocaleString(),
      }),
    },
  },
  setup(props, { emit }) {
    const currentForm = useVModel(props, 'value')
    const isFormOpen = ref(false)
    const headers = ref([
      {
        text: '閾値',
        value: 'attributes.threshold',
      },
      {
        text: '形容詞',
        value: 'attributes.adjective',
      },
      ...props.headersExt,
      {
        text: '',
        value: 'actions',
        align: 'right',
        sortable: false,
        groupable: false,
      },
    ])
    const adjectives = [
      { value: 'greater_than', text: 'より多い場合' },
      { value: 'greater_than_equal_to', text: '以上の場合' },
      { value: 'less_than_equal_to', text: '以下の場合' },
      { value: 'less_than', text: 'より少ない場合' },
      { value: 'equal_to', text: 'と等しい場合' },
      { value: 'notequal_to', text: 'と等しくない場合' },
    ]
    const adjectiveEnum = adjectives.reduce((obj, val) => ({ ...obj, [val.value]: val.text }), {})

    const isValid = computed(() => {
      const {
        threshold,
        adjective,
      } = currentForm.value

      return {
        threshold: threshold >= 0 || threshold < 0,
        adjective: map(adjectives, 'value').includes(adjective),
      }
    })

    const isValidToSubmit = computed(() => {
      return every(values(isValid.value), bool => bool) && !!props.validator(currentForm.value)
    })

    const getValue = (item, path) => {
      return get(item, path)
    }

    watch(() => isFormOpen.value, () => {
      emit('toggle-form', isFormOpen.value)
    })

    return {
      // data
      currentForm,
      isFormOpen,
      headers,
      adjectives,
      adjectiveEnum,

      // computed
      isValid,
      isValidToSubmit,

      // methods
      getValue,

      icons: {
        mdiPlus,
        mdiTrashCanOutline,
        mdiPencilOutline,
      },
    }
  },
}
</script>
